import "./index.scss";
import * as React from "react"
import HomePage from "./home";

const Index: React.FC<{}> = () => {
  return (
    <div className="shell">
       <HomePage />
    </div>
  )
}

export default Index